import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { DesktopOnlyPageError } from 'src/utils/errors/desktop-only-page-error';
import { BroadcastListPage } from './broadcast-list-page';

export const BroadcastPage = () => {
  const { t } = useTranslation(['broadcast', 'common']);
  useAddBreadcrumb(t('broadcast:pages.broadcast.pageBreadcrumb'));
  const { path } = useRouteMatch();
  const breakpointUpMd = useBreakpoint(up('md'));
  const { triggerError } = useErrorHandling();

  useEffect(() => {
    if (!breakpointUpMd) {
      triggerError(new DesktopOnlyPageError());
    }
  }, [breakpointUpMd, triggerError]);

  if (!breakpointUpMd) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path={`${path}/:broadcastId`}>
        <span>BROADCAST DETAIL PAGE</span>
      </Route>
      <Route exact path={path}>
        <BroadcastListPage />
      </Route>
    </Switch>
  );
};
