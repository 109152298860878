import {
  ContactInteractionWhatsappTemplateComponentBody,
  ContactInteractionWhatsappTemplateComponentHeader,
} from '@lgg/isomorphic/types/__generated__/graphql';

// Map whatsapp formatting syntax to the one supported by markdown-it
// _italic_ -> *italic*
// *bold* -> **bold**
// ~strikethrough~ -> ~~strikethrough~~
export const sanitizeWhatsappMessage = (message: string) => {
  return message
    .replace(/~(~*[^~\n]+~*)~/g, '~~$1~~')
    .replace(/\*(\**[^*\n]+\**)\*/g, '**$1**')
    .replace(/_(_*[^_\n]+_*)_/g, '*$1*');
};

export const resolveWhatsappComponentTextValue = (
  component:
    | ContactInteractionWhatsappTemplateComponentHeader
    | ContactInteractionWhatsappTemplateComponentBody,
) => {
  let resolvedText = component.text;

  component.variables.forEach((variable, index) => {
    resolvedText = resolvedText.replace(`{{${index + 1}}}`, variable.text);
  });

  return resolvedText;
};
