import { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationConversationSendWhatsappTemplateArgs,
  Query,
  QueryWhatsappTemplatesArgs,
  WhatsappTemplate,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  CONTACT_INTERACTION_WHATSAPP_TEMPLATE_COMPONENT_BUTTONS_FRAGMENT,
  WHATSAPP_TEMPLATE_COMPONENT_BUTTONS_FRAGMENT,
} from 'src/components/providers/apollo-provider-provider';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';

const WHATSAPP_TEMPLATE_QUERY = gql`
  ${WHATSAPP_TEMPLATE_COMPONENT_BUTTONS_FRAGMENT}
  query GetWhatsappTemplates(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $resourceId: ID!
    $where: WhatsappTemplateWhereInput
  ) {
    whatsappTemplates(
      institutionId: $institutionId
      resourceId: $resourceId
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { createdAt: ASC }
      where: $where
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          language
          status {
            isValid
            invalidReasonCode
          }
          components {
            __typename
            ... on WhatsappTemplateComponent {
              text
              format
            }
            ... on WhatsappTemplateComponentHeader {
              text
              examples
            }
            ... on WhatsappTemplateComponentBody {
              text
              examples
            }
            ... on WhatsappTemplateComponentFooter {
              text
            }
            ...WhatsappTemplateComponentButtonsFragment
          }
        }
      }
    }
  }
`;

const PAGE_SIZE = 15;

type UseWhatsappTemplateQueryProps = {
  keyword: string;
  language: string;
  resourceId: string;
  skip: boolean;
};

export const useWhatsappTemplateQuery = ({
  resourceId,
  keyword,
  language,
  skip,
}: UseWhatsappTemplateQueryProps) => {
  const { id: institutionId } = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();

  const variables = useMemo(() => {
    const baseVariables = {
      first: PAGE_SIZE,
      resourceId,
      institutionId,
    };

    let where: QueryWhatsappTemplatesArgs['where'] = {};

    if (keyword) {
      where = {
        ...where,
        matchKeywords: {
          _all: keyword,
        },
      };
    }

    if (language !== 'all') {
      where = {
        ...where,
        language: { _contains: language },
      };
    }

    return { ...baseVariables, where };
  }, [resourceId, institutionId, keyword, language]);

  return useInfiniteListQuery<
    Pick<Query, 'whatsappTemplates'>,
    Partial<QueryWhatsappTemplatesArgs>,
    WhatsappTemplate
  >({
    queryOptions: {
      query: WHATSAPP_TEMPLATE_QUERY,
      getNodeIdCallback: (template) => template?.id,
      notifyOnNetworkStatusChange: true,
      skip,
      variables,
      getEdgesCallback: (data) => data?.whatsappTemplates.edges ?? [],
      getPageInfoCallback: (data) => data?.whatsappTemplates.pageInfo,
      onError: handleGraphQLError,
      queryPageSize: PAGE_SIZE,
    },
  });
};

const SEND_WHATSAPP_TEMPLATE_MUTATION = gql`
  ${CONTACT_INTERACTION_WHATSAPP_TEMPLATE_COMPONENT_BUTTONS_FRAGMENT}
  mutation ConversationSendWhatsappTemplate(
    $templateId: String!
    $resourceId: ID!
    $conversationId: ID!
    $contactPhoneNumber: String
    $headerVariables: [ContactInteractionWhatsappTemplateComponentVariableInput!]!
    $bodyVariables: [ContactInteractionWhatsappTemplateComponentVariableInput!]!
  ) {
    conversationSendWhatsappTemplate(
      templateId: $templateId
      resourceId: $resourceId
      conversationId: $conversationId
      headerVariables: $headerVariables
      contactPhoneNumber: $contactPhoneNumber
      bodyVariables: $bodyVariables
    ) {
      interaction {
        id
        registrationType
        template {
          id
          language
          name
          components {
            ... on ContactInteractionWhatsappTemplateComponent {
              format
              text
            }
            ... on ContactInteractionWhatsappTemplateComponentHeader {
              variables {
                text
                type
              }
            }
            ... on ContactInteractionWhatsappTemplateComponentBody {
              variables {
                text
                type
              }
            }
            ...ContactInteractionWhatsappTemplateComponentButtonsFragment
          }
        }
      }
    }
  }
`;

export const useConversationSendWhatsappTemplateMutation = () => {
  return useMutation<
    Pick<Mutation, 'conversationSendWhatsappTemplate'>,
    MutationConversationSendWhatsappTemplateArgs
  >(SEND_WHATSAPP_TEMPLATE_MUTATION);
};
