import { ReactComponent as contact18 } from 'src/assets/icons/18/contact.svg';
import { ReactComponent as interaction18 } from 'src/assets/icons/18/interaction.svg';
import { ReactComponent as note18 } from 'src/assets/icons/18/note.svg';
import { ReactComponent as schedule18 } from 'src/assets/icons/18/schedule.svg';
import { ReactComponent as task18 } from 'src/assets/icons/18/task.svg';
import { ReactComponent as accounts } from 'src/assets/icons/accounts.svg';
import {
  ReactComponent as mail,
  ReactComponent as call,
} from 'src/assets/icons/action_call.svg';
import { ReactComponent as chat } from 'src/assets/icons/action_chat.svg';
import { ReactComponent as activity } from 'src/assets/icons/activity.svg';
import { ReactComponent as add } from 'src/assets/icons/add.svg';
import { ReactComponent as agency } from 'src/assets/icons/agency.svg';
import { ReactComponent as arrowNext } from 'src/assets/icons/arrow_next.svg';
import { ReactComponent as arrowPrevious } from 'src/assets/icons/arrow_previous.svg';
import { ReactComponent as arrowdown } from 'src/assets/icons/arrowdown.svg';
import { ReactComponent as arrowright } from 'src/assets/icons/arrowright.svg';
import { ReactComponent as arrowup } from 'src/assets/icons/arrowup.svg';
import { ReactComponent as assignedto } from 'src/assets/icons/assignedto.svg';
import { ReactComponent as billing } from 'src/assets/icons/billing.svg';
import { ReactComponent as block } from 'src/assets/icons/block.svg';
import { ReactComponent as broadcast } from 'src/assets/icons/broadcast.svg';
import { ReactComponent as calendar } from 'src/assets/icons/calendar.svg';
import { ReactComponent as calendarNoPadding } from 'src/assets/icons/calendar_nopadding.svg';
import { ReactComponent as callflow } from 'src/assets/icons/callflow.svg';
import { ReactComponent as campaign } from 'src/assets/icons/campaign.svg';
import { ReactComponent as checkSave } from 'src/assets/icons/check_save.svg';
import { ReactComponent as chevronRight } from 'src/assets/icons/chevron-right.svg';
import { ReactComponent as close } from 'src/assets/icons/close.svg';
import { ReactComponent as closesidebar } from 'src/assets/icons/closesidebar.svg';
import { ReactComponent as companies } from 'src/assets/icons/companies.svg';
import { ReactComponent as company } from 'src/assets/icons/company.svg';
import { ReactComponent as contactSetting } from 'src/assets/icons/contact-setting.svg';
import { ReactComponent as contact } from 'src/assets/icons/contact.svg';
import { ReactComponent as contactInteractionDirection } from 'src/assets/icons/contact_interaction_direction.svg';
import { ReactComponent as contacts } from 'src/assets/icons/contacts.svg';
import { ReactComponent as conversation } from 'src/assets/icons/conversation.svg';
import { ReactComponent as conversationsMenuIcon } from 'src/assets/icons/conversations_menu_icon.svg';
import { ReactComponent as download } from 'src/assets/icons/download.svg';
import { ReactComponent as dropdown } from 'src/assets/icons/dropdown.svg';
import { ReactComponent as edit } from 'src/assets/icons/edit.svg';
import { ReactComponent as email } from 'src/assets/icons/email.svg';
import { ReactComponent as exportbtn } from 'src/assets/icons/export.svg';
import { ReactComponent as filters } from 'src/assets/icons/filters.svg';
import { ReactComponent as gear } from 'src/assets/icons/gear.svg';
import { ReactComponent as group } from 'src/assets/icons/group.svg';
import { ReactComponent as headerAccount } from 'src/assets/icons/header-account.svg';
import { ReactComponent as headerCall } from 'src/assets/icons/header-call.svg';
import { ReactComponent as headerCallflow } from 'src/assets/icons/header-callflow.svg';
import { ReactComponent as headerCampaign } from 'src/assets/icons/header-campaign.svg';
import { ReactComponent as headerDepartment } from 'src/assets/icons/header-department.svg';
import { ReactComponent as headerFacebook } from 'src/assets/icons/header-facebook.svg';
import { ReactComponent as headerGroup } from 'src/assets/icons/header-group.svg';
import { ReactComponent as headerSource } from 'src/assets/icons/header-source.svg';
import { ReactComponent as headerTags } from 'src/assets/icons/header-tags.svg';
import { ReactComponent as arrowLeftWide } from 'src/assets/icons/icon-arrow-left-wide.svg';
import { ReactComponent as callAnswered } from 'src/assets/icons/icon-callanswered.svg';
import { ReactComponent as callCancelled } from 'src/assets/icons/icon-callcancelled.svg';
import { ReactComponent as openConversation } from 'src/assets/icons/icon-conversation-chat-open.svg';
import { ReactComponent as iconFacebookForm } from 'src/assets/icons/icon-form.svg';
import { ReactComponent as callHangup } from 'src/assets/icons/icon-hangup.svg';
import { ReactComponent as instagramAlt } from 'src/assets/icons/icon-instagram-alt.svg';
import { ReactComponent as iconInteraction } from 'src/assets/icons/icon-interaction.svg';
import { ReactComponent as iconLoader } from 'src/assets/icons/icon-loader.svg';
import { ReactComponent as time } from 'src/assets/icons/icon-time.svg';
import { ReactComponent as unlink } from 'src/assets/icons/icon-unlink.svg';
import { ReactComponent as activityCampaign } from 'src/assets/icons/icon_activity_campaign.svg';
import { ReactComponent as activityDepartment } from 'src/assets/icons/icon_activity_department.svg';
import { ReactComponent as activitySource } from 'src/assets/icons/icon_activity_source.svg';
import { ReactComponent as agentSpeaker } from 'src/assets/icons/icon_agent_speaker.svg';
import { ReactComponent as iconAi } from 'src/assets/icons/icon_ai.svg';
import { ReactComponent as aiWand } from 'src/assets/icons/icon_aiwand.svg';
import { ReactComponent as errorAlertIcon } from 'src/assets/icons/icon_alert_error.svg';
import { ReactComponent as errorAlertIconBig } from 'src/assets/icons/icon_alert_error_big.svg';
import { ReactComponent as infoAlertIcon } from 'src/assets/icons/icon_alert_info.svg';
import { ReactComponent as infoAlertIconBig } from 'src/assets/icons/icon_alert_info_big.svg';
import { ReactComponent as successAlertIcon } from 'src/assets/icons/icon_alert_success.svg';
import { ReactComponent as successAlertIconBig } from 'src/assets/icons/icon_alert_success_big.svg';
import { ReactComponent as warningAlertIcon } from 'src/assets/icons/icon_alert_warning.svg';
import { ReactComponent as warningAlertIconBig } from 'src/assets/icons/icon_alert_warning_big.svg';
import { ReactComponent as appointmentCancelled } from 'src/assets/icons/icon_appointment_canceled.svg';
import { ReactComponent as appointmentCompleted } from 'src/assets/icons/icon_appointment_completed.svg';
import { ReactComponent as appointmentNoShow } from 'src/assets/icons/icon_appointment_noshow.svg';
import { ReactComponent as appointmentRescheduled } from 'src/assets/icons/icon_appointment_rescheduled.svg';
import { ReactComponent as appointmentScheduled } from 'src/assets/icons/icon_appointment_scheduled.svg';
import { ReactComponent as arrowRightWide } from 'src/assets/icons/icon_arrow_right.svg';
import { ReactComponent as arrowBackNoPadding } from 'src/assets/icons/icon_arrowback_nopadding.svg';
import { ReactComponent as arrowNextNoPadding } from 'src/assets/icons/icon_arrownext_nopadding.svg';
import { ReactComponent as attach } from 'src/assets/icons/icon_attach.svg';
import { ReactComponent as attachedAudio } from 'src/assets/icons/icon_attached_audio.svg';
import { ReactComponent as attachedFile } from 'src/assets/icons/icon_attached_file.svg';
import { ReactComponent as attachedImage } from 'src/assets/icons/icon_attached_image.svg';
import { ReactComponent as attachedVideo } from 'src/assets/icons/icon_attached_video.svg';
import { ReactComponent as attachment } from 'src/assets/icons/icon_attachment.svg';
import { ReactComponent as iconBackTop } from 'src/assets/icons/icon_back_top_mobile.svg';
import { ReactComponent as badgeCheck } from 'src/assets/icons/icon_badge-check.svg';
import { ReactComponent as bulkActions } from 'src/assets/icons/icon_bulkactions.svg';
import { ReactComponent as callBack } from 'src/assets/icons/icon_call_back.svg';
import { ReactComponent as callIncoming } from 'src/assets/icons/icon_callincoming.svg';
import { ReactComponent as callOutgoing } from 'src/assets/icons/icon_calloutgoing.svg';
import { ReactComponent as camera } from 'src/assets/icons/icon_camera.svg';
import { ReactComponent as whatsappFilled } from 'src/assets/icons/icon_channels_whatsapp.svg';
import { ReactComponent as taskCompleted } from 'src/assets/icons/icon_check_completed.svg';
import { ReactComponent as circularCheck } from 'src/assets/icons/icon_circular_check.svg';
import { ReactComponent as contactActions } from 'src/assets/icons/icon_contactactions.svg';
import { ReactComponent as chatOpen } from 'src/assets/icons/icon_conversation_chat_open.svg';
import { ReactComponent as conversations } from 'src/assets/icons/icon_conversations.svg';
import { ReactComponent as conversationsAll } from 'src/assets/icons/icon_conversations_all.svg';
import { ReactComponent as conversationsNotReplied } from 'src/assets/icons/icon_conversations_not_replied.svg';
import { ReactComponent as snooze } from 'src/assets/icons/icon_conversations_snoozed.svg';
import { ReactComponent as snoozeThreeHours } from 'src/assets/icons/icon_conversations_snoozed_3hours.svg';
import { ReactComponent as snoozeLaterToday } from 'src/assets/icons/icon_conversations_snoozed_latertoday.svg';
import { ReactComponent as snoozeNextWeek } from 'src/assets/icons/icon_conversations_snoozed_nextweek.svg';
import { ReactComponent as snoozePickDay } from 'src/assets/icons/icon_conversations_snoozed_pickday.svg';
import { ReactComponent as snoozeTomorrow } from 'src/assets/icons/icon_conversations_snoozed_tomorrow.svg';
import { ReactComponent as copy } from 'src/assets/icons/icon_copy.svg';
import { ReactComponent as currentInteraction } from 'src/assets/icons/icon_current_interaction.svg';
import { ReactComponent as debug } from 'src/assets/icons/icon_debug.svg';
import { ReactComponent as deleteBin } from 'src/assets/icons/icon_delete.svg';
import { ReactComponent as department } from 'src/assets/icons/icon_departments.svg';
import { ReactComponent as details } from 'src/assets/icons/icon_details.svg';
import { ReactComponent as discardSnooze } from 'src/assets/icons/icon_discardsnooze.svg';
import { ReactComponent as doNotDisturb } from 'src/assets/icons/icon_do_not_disturb.svg';
import { ReactComponent as document } from 'src/assets/icons/icon_document.svg';
import { ReactComponent as doubleTick } from 'src/assets/icons/icon_doubletick.svg';
import { ReactComponent as emoji } from 'src/assets/icons/icon_emoji.svg';
import { ReactComponent as happyEmoji } from 'src/assets/icons/icon_emoji_happy.svg';
import { ReactComponent as neutralEmoji } from 'src/assets/icons/icon_emoji_neutral.svg';
import { ReactComponent as sadEmoji } from 'src/assets/icons/icon_emoji_sad.svg';
import { ReactComponent as networkError } from 'src/assets/icons/icon_error_network.svg';
import { ReactComponent as everyone } from 'src/assets/icons/icon_everyone.svg';
import { ReactComponent as externalLink } from 'src/assets/icons/icon_external_link.svg';
import { ReactComponent as facebook } from 'src/assets/icons/icon_facebook.svg';
import { ReactComponent as facebookIntegrationProblem } from 'src/assets/icons/icon_facebook_integration_problem.svg';
import { ReactComponent as facebookMessenger } from 'src/assets/icons/icon_facebook_messenger.svg';
import { ReactComponent as facebookMessengerAlt } from 'src/assets/icons/icon_facebook_messenger_fill.svg';
import { ReactComponent as conversationFilters } from 'src/assets/icons/icon_filters.svg';
import { ReactComponent as forward } from 'src/assets/icons/icon_forward.svg';
import { ReactComponent as taskInProgress } from 'src/assets/icons/icon_in_progress.svg';
import { ReactComponent as info } from 'src/assets/icons/icon_info.svg';
import { ReactComponent as infoAlertRedIcon } from 'src/assets/icons/icon_info_red.svg';
import { ReactComponent as inPerson } from 'src/assets/icons/icon_inperson.svg';
import { ReactComponent as instagram } from 'src/assets/icons/icon_instagram.svg';
import { ReactComponent as ivrSpeaker } from 'src/assets/icons/icon_ivr_speaker.svg';
import { ReactComponent as link } from 'src/assets/icons/icon_link.svg';
import { ReactComponent as location } from 'src/assets/icons/icon_location.svg';
import { ReactComponent as lock } from 'src/assets/icons/icon_lock.svg';
import { ReactComponent as magnifyingGlass } from 'src/assets/icons/icon_magnifying_glass.svg';
import { ReactComponent as mailAlt } from 'src/assets/icons/icon_mail_fill.svg';
import { ReactComponent as manualInteraction } from 'src/assets/icons/icon_manual_interaction.svg';
import { ReactComponent as maximize } from 'src/assets/icons/icon_maximize.svg';
import { ReactComponent as mediatypeImage } from 'src/assets/icons/icon_mediatype_image.svg';
import { ReactComponent as mediatypeVideo } from 'src/assets/icons/icon_mediatype_video.svg';
import { ReactComponent as mention } from 'src/assets/icons/icon_mention.svg';
import { ReactComponent as minimize } from 'src/assets/icons/icon_minimize.svg';
import { ReactComponent as missedCall } from 'src/assets/icons/icon_missedcall.svg';
import { ReactComponent as notificationMuted } from 'src/assets/icons/icon_notification-muted.svg';
import { ReactComponent as notification } from 'src/assets/icons/icon_notification.svg';
import { ReactComponent as opportunity } from 'src/assets/icons/icon_opportunity.svg';
import { ReactComponent as pause } from 'src/assets/icons/icon_pause.svg';
import { ReactComponent as pin } from 'src/assets/icons/icon_pin.svg';
import { ReactComponent as play } from 'src/assets/icons/icon_play.svg';
import { ReactComponent as plusThick } from 'src/assets/icons/icon_plus_thick.svg';
import { ReactComponent as priority } from 'src/assets/icons/icon_priority.svg';
import { ReactComponent as recentFile } from 'src/assets/icons/icon_recent_file.svg';
import { ReactComponent as reply } from 'src/assets/icons/icon_reply.svg';
import { ReactComponent as report } from 'src/assets/icons/icon_report.svg';
import { ReactComponent as restart } from 'src/assets/icons/icon_restart.svg';
import { ReactComponent as send } from 'src/assets/icons/icon_send.svg';
import { ReactComponent as settingsNoPadding } from 'src/assets/icons/icon_settings_nopadding.svg';
import { ReactComponent as whatsappWithPadding } from 'src/assets/icons/icon_sidebar_whatsapp.svg';
import { ReactComponent as sms } from 'src/assets/icons/icon_sms.svg';
import { ReactComponent as smsAlt } from 'src/assets/icons/icon_sms_fill.svg';
import { ReactComponent as star } from 'src/assets/icons/icon_star.svg';
import { ReactComponent as summary } from 'src/assets/icons/icon_summary.svg';
import { ReactComponent as time1Hour } from 'src/assets/icons/icon_time_1_hour.svg';
import { ReactComponent as time2Hours } from 'src/assets/icons/icon_time_2_hours.svg';
import { ReactComponent as time4Hours } from 'src/assets/icons/icon_time_4_hours.svg';
import { ReactComponent as taskYetToStart } from 'src/assets/icons/icon_timer_soon.svg';
import { ReactComponent as today } from 'src/assets/icons/icon_today.svg';
import { ReactComponent as transcript } from 'src/assets/icons/icon_transcript.svg';
import { ReactComponent as unactive } from 'src/assets/icons/icon_unactive.svg';
import { ReactComponent as unknownSpeaker } from 'src/assets/icons/icon_unknown_speaker.svg';
import { ReactComponent as unmark } from 'src/assets/icons/icon_unmark.svg';
import { ReactComponent as updateStatus } from 'src/assets/icons/icon_update_status.svg';
import { ReactComponent as upload } from 'src/assets/icons/icon_upload.svg';
import { ReactComponent as voicemail } from 'src/assets/icons/icon_voicemail.svg';
import { ReactComponent as inPersonLog } from 'src/assets/icons/icon_walkin.svg';
import { ReactComponent as whatsapp } from 'src/assets/icons/icon_whatsapp.svg';
import { ReactComponent as whatsappProblem } from 'src/assets/icons/icon_whatsapp_problem.svg';
import { ReactComponent as world } from 'src/assets/icons/icon_world.svg';
import { ReactComponent as importIcon } from 'src/assets/icons/import.svg';
import { ReactComponent as institution } from 'src/assets/icons/institution.svg';
import { ReactComponent as interaction } from 'src/assets/icons/interaction.svg';
import { ReactComponent as interest } from 'src/assets/icons/interest.svg';
import { ReactComponent as logout } from 'src/assets/icons/logout.svg';
import { ReactComponent as mark } from 'src/assets/icons/marked.svg';
import { ReactComponent as messageDeliveredStatus } from 'src/assets/icons/message_delivery_status_delivered.svg';
import { ReactComponent as messagePendingStatus } from 'src/assets/icons/message_delivery_status_pending.svg';
import { ReactComponent as messageReadStatus } from 'src/assets/icons/message_delivery_status_read.svg';
import { ReactComponent as messageSentStatus } from 'src/assets/icons/message_delivery_status_sent.svg';
import { ReactComponent as moreOptions } from 'src/assets/icons/more-options.svg';
import { ReactComponent as moreOptionsWithPadding } from 'src/assets/icons/more_options_with_padding.svg';
import { ReactComponent as note } from 'src/assets/icons/note.svg';
import { ReactComponent as opensidebar } from 'src/assets/icons/opensidebar.svg';
import { ReactComponent as organization } from 'src/assets/icons/organization.svg';
import { ReactComponent as pageError } from 'src/assets/icons/page_error.svg';
import { ReactComponent as phone } from 'src/assets/icons/phone.svg';
import { ReactComponent as playback } from 'src/assets/icons/playback.svg';
import { ReactComponent as plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as priorityHigh } from 'src/assets/icons/priority_high.svg';
import { ReactComponent as priorityLow } from 'src/assets/icons/priority_low.svg';
import { ReactComponent as priorityNormal } from 'src/assets/icons/priority_normal.svg';
import { ReactComponent as reports } from 'src/assets/icons/reports.svg';
import { ReactComponent as schedule } from 'src/assets/icons/schedule.svg';
import { ReactComponent as scheduled } from 'src/assets/icons/scheduled.svg';
import { ReactComponent as selectArrow } from 'src/assets/icons/selectArrow.svg';
import { ReactComponent as settings } from 'src/assets/icons/settings.svg';
import { ReactComponent as share } from 'src/assets/icons/share.svg';
import { ReactComponent as sideBarOrganization } from 'src/assets/icons/sidebar-organization.svg';
import { ReactComponent as sort } from 'src/assets/icons/sort.svg';
import { ReactComponent as status } from 'src/assets/icons/status.svg';
import { ReactComponent as support } from 'src/assets/icons/support.svg';
import { ReactComponent as sync } from 'src/assets/icons/sync.svg';
import { ReactComponent as system } from 'src/assets/icons/system.svg';
import { ReactComponent as tabActivity } from 'src/assets/icons/tab_activity.svg';
import { ReactComponent as tabNotes } from 'src/assets/icons/tab_notes.svg';
import { ReactComponent as tabSchedules } from 'src/assets/icons/tab_schedules.svg';
import { ReactComponent as tabTasks } from 'src/assets/icons/tab_tasks.svg';
import { ReactComponent as tabTimeline } from 'src/assets/icons/tab_timeline.svg';
import { ReactComponent as tags } from 'src/assets/icons/tags.svg';
import { ReactComponent as task } from 'src/assets/icons/task.svg';
import { ReactComponent as tracking } from 'src/assets/icons/tracking.svg';
import { ReactComponent as unmarkConversation } from 'src/assets/icons/unmark_conversation.svg';
import { ReactComponent as userAvatar } from 'src/assets/icons/useravatar.svg';
import { ReactComponent as userplus } from 'src/assets/icons/userplus.svg';
import { ReactComponent as whatsappTemplates } from 'src/assets/icons/whatsapp_templates.svg';

export const icons: any = {
  accounts,
  activity,
  activityCampaign,
  activityDepartment,
  activitySource,
  add,
  agency,
  agentSpeaker,
  aiWand,
  appointmentCancelled,
  appointmentCompleted,
  appointmentNoShow,
  appointmentRescheduled,
  appointmentScheduled,
  arrowBackNoPadding,
  arrowdown,
  arrowLeftWide,
  arrowNext,
  arrowNextNoPadding,
  arrowPrevious,
  arrowright,
  arrowRightWide,
  arrowup,
  assignedto,
  attach,
  attachedAudio,
  attachedFile,
  attachedImage,
  attachedVideo,
  attachment,
  badgeCheck,
  billing,
  block,
  broadcast,
  bulkActions,
  calendar,
  calendarNoPadding,
  call,
  callAnswered,
  callBack,
  callCancelled,
  callflow,
  callHangup,
  callIncoming,
  callOutgoing,
  camera,
  campaign,
  chat,
  chatOpen,
  checkSave,
  chevronRight,
  circularCheck,
  close,
  closesidebar,
  companies,
  company,
  contact,
  contact18,
  contactActions,
  contactInteractionDirection,
  contacts,
  conversation,
  contactSetting,
  conversationFilters,
  conversations,
  conversationsAll,
  conversationsMenuIcon,
  conversationsNotReplied,
  copy,
  currentInteraction,
  debug,
  deleteBin,
  department,
  details,
  discardSnooze,
  document,
  doNotDisturb,
  doubleTick,
  download,
  dropdown,
  edit,
  email,
  emoji,
  errorAlertIcon,
  errorAlertIconBig,
  everyone,
  exportbtn,
  externalLink,
  facebook,
  facebookIntegrationProblem,
  facebookMessenger,
  facebookMessengerAlt,
  filters,
  forward,
  gear,
  group,
  happyEmoji,
  headerAccount,
  headerCall,
  headerCallflow,
  headerCampaign,
  headerDepartment,
  headerFacebook,
  headerGroup,
  headerSource,
  headerTags,
  iconAi,
  iconBackTop,
  iconFacebookForm,
  iconInteraction,
  iconLoader,
  import: importIcon,
  info,
  infoAlertIcon,
  infoAlertIconBig,
  infoAlertRedIcon,
  inPerson,
  inPersonLog,
  instagram,
  instagramAlt,
  institution,
  interaction,
  interaction18,
  interest,
  ivrSpeaker,
  link,
  location,
  lock,
  logout,
  magnifyingGlass,
  mail,
  mailAlt,
  manualInteraction,
  mark,
  maximize,
  mediatypeImage,
  mediatypeVideo,
  mention,
  messageDeliveredStatus,
  messagePendingStatus,
  messageReadStatus,
  messageSentStatus,
  minimize,
  missedCall,
  moreOptions,
  moreOptionsWithPadding,
  networkError,
  neutralEmoji,
  note,
  note18,
  notification,
  notificationMuted,
  openConversation,
  opensidebar,
  opportunity,
  organization,
  pageError,
  pause,
  phone,
  pin,
  play,
  playback,
  plus,
  plusThick,
  priority,
  priorityHigh,
  priorityLow,
  priorityNormal,
  recentFile,
  reply,
  report,
  reports,
  restart,
  sadEmoji,
  schedule,
  schedule18,
  scheduled,
  selectArrow,
  send,
  settings,
  settingsNoPadding,
  share,
  sidebarorganization: sideBarOrganization,
  sms,
  smsAlt,
  snooze,
  snoozeLaterToday,
  snoozeNextWeek,
  snoozePickDay,
  snoozeThreeHours,
  snoozeTomorrow,
  sort,
  star,
  status,
  successAlertIcon,
  successAlertIconBig,
  summary,
  support,
  sync,
  system,
  tabActivity,
  tabNotes,
  tabSchedules,
  tabTasks,
  tabTimeline,
  tags,
  task,
  task18,
  taskCompleted,
  taskInProgress,
  taskYetToStart,
  time,
  time1Hour,
  time2Hours,
  time4Hours,
  today,
  tracking,
  transcript,
  unactive,
  unknownSpeaker,
  unlink,
  unmark,
  unmarkConversation,
  updateStatus,
  upload,
  userAvatar,
  userplus,
  voicemail,
  warningAlertIcon,
  warningAlertIconBig,
  whatsapp,
  whatsappFilled,
  whatsappProblem,
  whatsappTemplates,
  whatsappWithPadding,
  world,
};

export type IconItem = keyof typeof icons;
