import React from 'react';
import { useTranslation } from 'react-i18next';
import { LggOptionsDropdownButton } from 'src/components/general/button/dropdown-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { TableLayoutHeader } from 'src/components/general/table-helpers';
import { PageContainer } from 'src/components/layout/page-containers';
import { BroadcastTable } from 'src/components/pages/broadcast/components/broadcast-table';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useVisible } from 'src/hooks/use-visible';

export const BroadcastListPage = () => {
  const { t } = useTranslation(['broadcast', 'common']);
  useAddBreadcrumb(t('broadcast:pages.broadcast.pageBreadcrumb'));
  const filtersVisibilityHandler = useVisible();
  const exportVisibilityHandler = useVisible();

  // TODO:
  // - Link API data
  // - Add pagination
  // - Enable table sorting
  // - Add filters
  // - Add export button logic
  // - Add view, delete and cancel actions.

  // if (!data || loading) {
  //   return (
  //     <TableLayoutPageContainer>
  //       <TableLayoutPlaceholder />
  //     </TableLayoutPageContainer>
  //   );
  // }

  return (
    <PageContainer data-lgg-id="broadcast-page">
      <TableLayoutHeader
        leftContent={
          <PaginationInfo
            total={15}
            testId="broadcast-page-pagination-info"
            entityName="broadcast"
          />
        }
        rightContent={
          <>
            <FiltersButton
              filtersVisibilityHandler={filtersVisibilityHandler}
              hasActiveFilters={false}
            />
            <LggOptionsDropdownButton
              variant="defaultWhite"
              size="regular"
              icon="exportbtn"
              loading={false}
              visibilityHandler={exportVisibilityHandler}
              options={[]}
              data-lgg-id="broadcast-page-export-button"
            >
              {t('common:actions.export')}
            </LggOptionsDropdownButton>
          </>
        }
      />
      <BroadcastTable
        data={[
          {
            id: 1,
            name: 'Broadcast name',
            audience: 1255,
            sent: 300,
            replies: 100,
            optOuts: 2,
            scheduledStartAt: new Date(),
            status: 'SENT',
          },
          {
            id: 11,
            name: 'Broadcast name good',
            audience: 1255,
            sent: 300,
            replies: 100,
            optOuts: 10,
            scheduledStartAt: new Date(),
            status: 'INPROGRESS',
          },
          {
            id: 111,
            name: 'Broadcast name bad',
            audience: 1255,
            sent: 300,
            replies: 100,
            optOuts: 20,
            scheduledStartAt: new Date(),
            status: 'SENT',
          },
          {
            id: 2,
            name: 'Broadcast name 2',
            audience: 5000,
            optOuts: 0,
            replies: 0,
            sent: 0,
            scheduledStartAt: new Date(),
            status: 'SCHEDULED',
          },
          {
            id: 3,
            name: 'Broadcast name 3',
            audience: 5000,
            optOuts: 0,
            replies: 0,
            sent: 0,
            scheduledStartAt: new Date(),
            status: 'CANCELLED',
          },
          {
            id: 5,
            name: 'Broadcast name 5',
            audience: 500,
            optOuts: 0,
            replies: 0,
            sent: 0,
            scheduledStartAt: new Date(),
            status: 'DRAFT',
          },
        ]}
        onChange={() => {}}
        sortData={{ direction: 'ASC', key: '' }}
      />
    </PageContainer>
  );
};
