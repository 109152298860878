import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import Animate from 'rc-animate';
import { up } from 'styled-breakpoints';
import { Icon } from 'src/components/general/icon';
import {
  SidebarMenuItem,
  SidebarSubMenu,
} from 'src/components/layout/authenticated-layout/sidebar/menu/menu-items';
import {
  SidebarMenuProps,
  StyledMenu,
} from 'src/components/layout/authenticated-layout/sidebar/menu/shared';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useInstitutionPathPrefix } from 'src/hooks/use-institution-path-prefix';
import { SidebarService } from 'src/services/sidebar.service';

export const CompanyMenu = memo<SidebarMenuProps>((props) => {
  const { hasPermission, getFeatureFlag } = useAuthorization();
  const { sidebarIsCollapsed } = useServiceSelector(SidebarService, (s) => ({
    sidebarIsCollapsed: s.state.isCollapsed,
  }));
  const breakpointUpMd = useBreakpoint(up('md'));

  const institution = useCurrentInstitution();
  const { prefix: institutionUrl } = useInstitutionPathPrefix(institution);

  const { t } = useTranslation(['common']);

  let ContactsMenuItem: React.ReactNode;

  if (getFeatureFlag('ux-redesign-v2.5')) {
    ContactsMenuItem = (
      <SidebarMenuItem
        icon="contacts"
        data-lgg-id="sidebar-contacts-menu-item"
        key={`${institutionUrl}/contacts/`}
        to={`${institutionUrl}/contacts/`}
        label={t('common:sidebar.contacts')}
      />
    );
  } else if (getFeatureFlag('crm') && hasPermission('cms.client.access')) {
    ContactsMenuItem = (
      <SidebarMenuItem
        icon="contacts"
        data-lgg-id="sidebar-contacts-menu-item"
        key={`${institutionUrl}/cms/clients/`}
        to={`${institutionUrl}/cms/clients/`}
        label={t('common:sidebar.contacts')}
      />
    );
  } else if (hasPermission('company:tracking.contacts.access')) {
    ContactsMenuItem = (
      <SidebarMenuItem
        icon="contacts"
        data-lgg-id="sidebar-contacts-menu-item"
        key={`${institutionUrl}/contacts/`}
        to={`${institutionUrl}/contacts/`}
        label={t('common:sidebar.contacts')}
      />
    );
  }

  return (
    <StyledMenu {...props}>
      {getFeatureFlag('conversations') && (
        <SidebarMenuItem
          data-lgg-id="sidebar-conversations-menu-item"
          icon="conversationsMenuIcon"
          key={`${institutionUrl}/conversations/`}
          to={`${institutionUrl}/conversations/`}
          label={t('common:sidebar.conversations')}
        />
      )}
      {getFeatureFlag('channel_whatsapp') &&
        getFeatureFlag(
          'temp_lggdev-1116_como-usuario-quiero-tener-la-habilidad-de-poder-acceder-a-la-opcion-de-canales-de-whatsapp-para-pode',
        ) &&
        hasPermission('channels.read') &&
        breakpointUpMd && (
          <SidebarMenuItem
            data-lgg-id="sidebar-whatsapp-channel-menu-item"
            icon="whatsappWithPadding"
            key={`${institutionUrl}/whatsapp-channel/`}
            to={`${institutionUrl}/whatsapp-channel/`}
            label={t('common:sidebar.whatsappChannel')}
          />
        )}
      {getFeatureFlag('broadcast') && breakpointUpMd && (
        <SidebarMenuItem
          data-lgg-id="sidebar-broadcast-menu-item"
          icon="broadcast"
          key={`${institutionUrl}/broadcast/`}
          to={`${institutionUrl}/broadcast/`}
          label={t('common:sidebar.broadcast')}
        />
      )}
      {hasPermission('lead.access') && (
        <SidebarMenuItem
          icon="activity"
          data-lgg-id="sidebar-activity-menu-item"
          key={`${institutionUrl}/activity/`}
          to={`${institutionUrl}/activity/`}
          label={t('common:sidebar.activity')}
        />
      )}
      {ContactsMenuItem}
      {getFeatureFlag('crm') && (
        <SidebarMenuItem
          icon="calendar"
          data-lgg-id="sidebar-calendar-menu-item"
          key={`${institutionUrl}/cms/calendar/`}
          to={`${institutionUrl}/cms/calendar/`}
          label={t('common:sidebar.calendar')}
        />
      )}
      {getFeatureFlag('crm') && (
        <SidebarMenuItem
          icon="task"
          data-lgg-id="sidebar-tasks-menu-item"
          key={`${institutionUrl}/cms/tasks/`}
          to={`${institutionUrl}/cms/tasks/`}
          label={t('common:sidebar.tasks')}
        />
      )}
      {getFeatureFlag('crm') && (
        <SidebarMenuItem
          icon="schedule"
          data-lgg-id="sidebar-appointments-menu-item"
          key={`${institutionUrl}/cms/appointments/`}
          to={`${institutionUrl}/cms/appointments/`}
          label={t('common:sidebar.schedules')}
        />
      )}
      {hasPermission('report.access') && (
        <SidebarSubMenu
          key={`${institutionUrl}/reports/`}
          to={`${institutionUrl}/reports/`}
          data-lgg-id="sidebar-reports-submenu-item"
          icon="reports"
          subMenuSelectedKeys={props.selectedKeys}
          title={
            <React.Fragment>
              <span>{t('common:sidebar.reports')}</span>
              <Animate transitionName="ant-fade">
                {!sidebarIsCollapsed && <Icon type="dropdown" className="lgg-arrow" />}
              </Animate>
            </React.Fragment>
          }
        >
          <SidebarMenuItem
            key={`${institutionUrl}/reports/overview/`}
            to={`${institutionUrl}/reports/overview/`}
            label={t('common:sidebar.overview')}
          />
          {getFeatureFlag('crm') && (
            <SidebarMenuItem
              key={`${institutionUrl}/reports/agents/performance/`}
              to={`${institutionUrl}/reports/agents/performance/`}
              label={t('common:sidebar.agentPerformance')}
            />
          )}
          <SidebarMenuItem
            data-lgg-id="sidebar-reports-feed-menu-item"
            key={`${institutionUrl}/reports/feed/`}
            to={`${institutionUrl}/reports/feed/`}
            label={t('common:sidebar.activityFeed')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/analysis/calls/`}
            to={`${institutionUrl}/reports/analysis/calls/`}
            label={t('common:sidebar.callAnalysis')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bygroup/bycostperlead/`}
            to={`${institutionUrl}/reports/leads/bygroup/bycostperlead/`}
            label={t('common:sidebar.costPerLead')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bydate/bycampaign/`}
            to={`${institutionUrl}/reports/leads/bydate/bycampaign/`}
            label={t('common:sidebar.leadsByCampaign')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bydate/bysource/`}
            to={`${institutionUrl}/reports/leads/bydate/bysource/`}
            label={t('common:sidebar.leadsBySource')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bydate/bymedium/`}
            to={`${institutionUrl}/reports/leads/bydate/bymedium/`}
            label={t('common:sidebar.leadsByMedium')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bydate/bychannel/`}
            to={`${institutionUrl}/reports/leads/bydate/bychannel/`}
            label={t('common:sidebar.leadsByChannel')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bydate/byagent/`}
            to={`${institutionUrl}/reports/leads/bydate/byagent/`}
            label={t('common:sidebar.leadsByAgent')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bygroup/byweekday/`}
            to={`${institutionUrl}/reports/leads/bygroup/byweekday/`}
            label={t('common:sidebar.leadsByDayOfWeek')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/bydate/byhour/`}
            to={`${institutionUrl}/reports/leads/bydate/byhour/`}
            label={t('common:sidebar.leadsByHourOfDay')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/reports/leads/interests/`}
            to={`${institutionUrl}/reports/leads/interests/`}
            label={t('common:sidebar.leadsInterest')}
          />
        </SidebarSubMenu>
      )}
      {hasPermission('campaign.access') && (
        <SidebarMenuItem
          icon="campaign"
          key={`${institutionUrl}/campaigns/`}
          to={`${institutionUrl}/campaigns/`}
          label={t('common:sidebar.campaigns')}
        />
      )}
      {hasPermission('tracking.access') && (
        <SidebarSubMenu
          key={`${institutionUrl}/tracking/`}
          to={`${institutionUrl}/tracking/`}
          subMenuSelectedKeys={props.selectedKeys}
          icon="tracking"
          title={
            <>
              <span>{t('common:sidebar.tracking')}</span>
              <Animate transitionName="ant-fade">
                {!sidebarIsCollapsed && <Icon type="dropdown" className="lgg-arrow" />}
              </Animate>
            </>
          }
        >
          {hasPermission('company.manage') && (
            <SidebarMenuItem
              key={`${institutionUrl}/tracking/configuration/`}
              to={`${institutionUrl}/tracking/configuration/`}
              label={t('common:sidebar.configuration')}
            />
          )}
          {hasPermission('tracking.resources.access') && (
            <SidebarMenuItem
              key={`${institutionUrl}/tracking/resources/`}
              to={`${institutionUrl}/tracking/resources/`}
              label={t('common:sidebar.resources')}
            />
          )}
          {hasPermission('tracking.tags.access') && (
            <SidebarMenuItem
              key={`${institutionUrl}/tracking/tags/`}
              to={`${institutionUrl}/tracking/tags/`}
              label={t('common:sidebar.tags')}
            />
          )}
          {hasPermission('tracking.callflows.access') && (
            <SidebarMenuItem
              key={`${institutionUrl}/tracking/callflows/`}
              to={`${institutionUrl}/tracking/callflows/`}
              label={t('common:sidebar.callFlows')}
            />
          )}
        </SidebarSubMenu>
      )}
      {hasPermission('account.access') && (
        <SidebarMenuItem
          icon="accounts"
          key={`${institutionUrl}/accounts/`}
          to={`${institutionUrl}/accounts/`}
          label={t('common:sidebar.accounts')}
        />
      )}
      {hasPermission('company.manage') && (
        <SidebarSubMenu
          key={`${institutionUrl}/settings/`}
          to={`${institutionUrl}/settings/`}
          subMenuSelectedKeys={props.selectedKeys}
          icon="settings"
          title={
            <React.Fragment>
              <span>{t('common:sidebar.settings')}</span>
              <Animate transitionName="ant-fade">
                {!sidebarIsCollapsed && <Icon type="dropdown" className="lgg-arrow" />}
              </Animate>
            </React.Fragment>
          }
        >
          {hasPermission('company:channel.manage') && (
            <>
              <SidebarMenuItem
                key={`${institutionUrl}/settings/facebook/`}
                to={`${institutionUrl}/settings/facebook/`}
                label={t('common:sidebar.facebookPages')}
              />
              {getFeatureFlag('channel_instagram') && (
                <SidebarMenuItem
                  key={`${institutionUrl}/settings/instagram/`}
                  to={`${institutionUrl}/settings/instagram/`}
                  label={t('common:sidebar.instagram')}
                />
              )}
            </>
          )}
          <SidebarMenuItem
            key={`${institutionUrl}/settings/departments/`}
            to={`${institutionUrl}/settings/departments/`}
            label={t('common:sidebar.departments')}
          />
          {hasPermission('leadreview.manage') && (
            <SidebarMenuItem
              key={`${institutionUrl}/settings/leadreview/callanalysis/`}
              to={`${institutionUrl}/settings/leadreview/callanalysis/`}
              label={t('common:sidebar.callReview')}
            />
          )}
          <SidebarMenuItem
            key={`${institutionUrl}/settings/recipients/`}
            to={`${institutionUrl}/settings/recipients/`}
            label={t('common:sidebar.notificationRecipients')}
          />
          <SidebarMenuItem
            key={`${institutionUrl}/settings/jobs/`}
            to={`${institutionUrl}/settings/jobs/`}
            label={t('common:sidebar.scheduledJobs')}
          />
        </SidebarSubMenu>
      )}
    </StyledMenu>
  );
});
