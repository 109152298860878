import React, { memo } from 'react';
import styled from 'styled-components';
import { ContactInteractionDirection } from '@lgg/isomorphic/types/__generated__/graphql';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  AttachmentDateAndStatus,
  BubbleTypeAttachmentContainer,
  IsomorphicAttachmentFileItemProps,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import {
  Content,
  ItemCaption,
  RepliedMessageRenderer,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { FileAttachment } from 'src/components/pages/conversations/components/general/file-attachment';

const FileItemCaption = styled(ItemCaption)`
  margin-left: 0;
`;

type FileAttachmentItemProps = IsomorphicAttachmentFileItemProps & {
  direction: ContactInteractionDirection;
  showDeliveryStatus: boolean;
};

export const FileAttachmentItem = memo<FileAttachmentItemProps>(
  ({ attachment, direction, attachmentDate, showDeliveryStatus, contactInteraction }) => {
    const { status = null, caption } = attachment;
    const { isMasked } = contactInteraction;

    return (
      <BubbleTypeAttachmentContainer direction={direction}>
        <RepliedMessageRenderer contactInteraction={contactInteraction} />
        <Content isBlurred={isMasked}>
          <FileAttachment attachment={attachment} />
        </Content>
        {caption && (
          <Content isBlurred={isMasked}>
            <FileItemCaption data-lgg-id="item-caption" direction={direction}>
              <TextFormatter>{caption}</TextFormatter>
            </FileItemCaption>
          </Content>
        )}
        <AttachmentDateAndStatus
          attachmentDate={attachmentDate}
          showStatus={showDeliveryStatus}
          attachmentStatus={status}
        />
      </BubbleTypeAttachmentContainer>
    );
  },
);
