import React, { memo, useEffect, useLayoutEffect } from 'react';
import {
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useParams,
  Link,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { useService } from 'react-service-locator';
import { gql, useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { Modal } from 'antd';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import { ContactInteractionUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import whiteLoaderAnimation from 'src/assets/animations/loader_white.json';
import { CallContactModal } from 'src/components/domain/contacts/call-contact-modal/call-contact-modal';
import { FlexColumn } from 'src/components/domain/contacts/contact-details/shared';
import { ContactModal } from 'src/components/domain/contacts/contact-modal/contact-modal';
import { EventTriggeredContactStatusModal } from 'src/components/domain/contacts/contact-status-modal';
import { ProfileSettingsModal } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { LegacyModalRedirect } from 'src/components/legacy/components/legacy-modal-redirect';
import { useLegacyInterop } from 'src/components/legacy/hooks/use-legacy-interop';
import { ActivityPage } from 'src/components/pages/activity/activity.page';
import { AppointmentsPage } from 'src/components/pages/appointments/appointments.page';
import { CalendarPage } from 'src/components/pages/calendar/calendar.page';
import { BottomSheetsPage } from 'src/components/pages/company/bottom-sheets-page';
import { PlanUpdateRequiredPage } from 'src/components/pages/company/plan-update-required.page';
import { UnblockContactModal } from 'src/components/pages/contacts/components/contact-block';
import { ContactsPage } from 'src/components/pages/contacts/contacts.page';
import {
  ConversationUrlModal,
  EventTriggeredConversationModal,
} from 'src/components/pages/conversations/components/event-triggered-conversation-modal';
import { Conversations } from 'src/components/pages/conversations/conversations.page';
import { LegacyPage } from 'src/components/pages/legacy/legacy.page';
import { TasksPage } from 'src/components/pages/tasks/tasks.page';
import { WhatsappChannelPage } from 'src/components/pages/whatsapp-channel/whatsapp-channel.page';
import { LggSwitch, ModalRoute } from 'src/components/routing/lgg-switch';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { useModalUrl } from 'src/hooks/use-modal-url';
import { useUrls } from 'src/hooks/use-urls';
import { AuthorizationService } from 'src/services/authorization.service';
import { GlobalLoadingService } from 'src/services/global-loading.service';
import { BroadcastPage } from '../broadcast/broadcast-page';
import { DevFileAttachments } from './dev-file-attachments';
import { DevIconsPage } from './dev-icons-page';

const useExecutivePath = (): string | undefined => {
  const {
    state: { institution, role },
  } = useService(AuthorizationService, (s) => [s.state.institution, s.state.role]);
  const internalSalesTeamCompanyId = parseInt(
    import.meta.env.VITE_LEADGOGO_SALES_TEAM_COMPANY_ID,
  );
  if (
    internalSalesTeamCompanyId &&
    institution.id === internalSalesTeamCompanyId &&
    role.id === 'company.agent'
  ) {
    return `${import.meta.env.VITE_ADMIN_BACKEND_BASE_URL}/executive`;
  }
};

const useShowNewContactModal = () => {
  const { getFeatureFlag } = useAuthorization();

  return getFeatureFlag('ux-redesign-v2.5');
};

const useOverrideOpenRemoteModal = () => {
  const { getContactModalUrl } = useUrls();
  const history = useHistory();

  useEffect(() => {
    const legacyOpenRemoteModal = openRemoteModal;

    const remoteModalOverride: typeof openRemoteModal = (config, relatedTarget) => {
      const remoteModalUrl = config?.data?.url ?? '';
      const legacyContactUrlRegex = /i\/company\/\d+\/modal\/lead\/(\d+)\//;
      const legacyContactModalRegex =
        /i\/company\/\d+\/cms\/_modals\/client\/\?lead_id=(\d+).*/;

      const match =
        remoteModalUrl.match(legacyContactUrlRegex) ??
        remoteModalUrl.match(legacyContactModalRegex);
      const contactId = match?.[1];

      if (contactId) {
        history.push(getContactModalUrl(parseInt(contactId)));

        // We need to set this property to false, so the legacy anchor can be clicked again
        // for ref see:
        //   - https://github.com/leadgogo/monorepo/blob/develop/apps/admin-backend/public/js/custom.js#L120
        //   - https://github.com/leadgogo/monorepo/blob/develop/apps/admin-backend/public/js/custom.js#L487
        if (relatedTarget) {
          window.jQuery(relatedTarget).data('remoteModalLoading', false);
        }
      } else {
        legacyOpenRemoteModal(config, relatedTarget);
      }
    };

    window.openRemoteModal = remoteModalOverride;
  }, [getContactModalUrl, history]);
};

export const CompanyPage = memo(() => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const executivePath = useExecutivePath();
  const { requirePermission, getFeatureFlag, hasPermission } = useAuthorization();
  const institutionUrl = useInstitutionUrl();
  const hasFeatureConversationAccess = getFeatureFlag('conversations');
  const hasWhatsAppFeatureAccess =
    getFeatureFlag('channel_whatsapp') &&
    getFeatureFlag(
      'temp_lggdev-1116_como-usuario-quiero-tener-la-habilidad-de-poder-acceder-a-la-opcion-de-canales-de-whatsapp-para-pode',
    ) &&
    hasPermission('channels.read');

  const hasFFUxRedesignV2_5 = getFeatureFlag('ux-redesign-v2.5');
  const showNewContactModal = useShowNewContactModal();
  const contactsPageUrl = `${institutionUrl}contacts`;
  const tasksPageUrl = `${institutionUrl}cms/tasks`;
  const appointmentsPageUrl = `${institutionUrl}cms/appointments`;
  const isNativePlatform = Capacitor.isNativePlatform();
  const showPlanUpdateRequiredPage =
    isNativePlatform &&
    !hasFFUxRedesignV2_5 &&
    !matchPath(location.pathname, { path: `${path}/plan-update-required` });
  useLegacyInterop();
  useOverrideOpenRemoteModal();
  requirePermission('company.access');

  if (showPlanUpdateRequiredPage) {
    return <Redirect to={`${url}/plan-update-required`} />;
  }

  return (
    <>
      <LggSwitch>
        {isNativePlatform && (
          <Route exact path={`${path}/plan-update-required`}>
            <PlanUpdateRequiredPage />
          </Route>
        )}
        {hasFeatureConversationAccess && (
          <Route
            exact
            path={[
              `${path}/conversations`,
              `${path}/conversations/:conversationId`,
              `${path}/conversations/:conversationId/:contactInteractionId`,
            ]}
          >
            <Conversations />
          </Route>
        )}
        <Route
          path={path}
          exact
          render={() => {
            if (executivePath) {
              window.location.replace(executivePath);
            } else {
              const defaultPath = hasFeatureConversationAccess
                ? '/conversations'
                : '/activity';

              return (
                <Redirect
                  to={`${url.replace(/\/+$/, '')}${defaultPath}${location.search}`}
                />
              );
            }
          }}
        />
        {import.meta.env.MODE === 'development' && (
          <Route exact path={`${path}/bottom-drawers`}>
            <BottomSheetsPage />
          </Route>
        )}
        {import.meta.env.MODE === 'development' && (
          <Route exact path={`${path}/icons-showcase-dev`}>
            <DevIconsPage />
          </Route>
        )}
        <Route exact path={`${path}/activity`}>
          {hasFFUxRedesignV2_5 ? <ActivityPage /> : <LegacyPage />}
        </Route>
        {getFeatureFlag('crm') && (
          <Route exact path={`${path}/cms/schedules`}>
            <Redirect to={`${url}/cms/appointments${location.search}`} />
          </Route>
        )}
        {getFeatureFlag('crm') && (
          <Route exact path={`${path}/cms/appointments`}>
            {hasFFUxRedesignV2_5 ? <AppointmentsPage /> : <LegacyPage />}
          </Route>
        )}
        {hasWhatsAppFeatureAccess && (
          <Route path={`${path}/whatsapp-channel`}>
            <WhatsappChannelPage />
          </Route>
        )}
        {getFeatureFlag('broadcast') && (
          <Route path={`${path}/broadcast`}>
            <BroadcastPage />
          </Route>
        )}
        <Route exact path={`${path}/cms/tasks`}>
          {hasFFUxRedesignV2_5 ? <TasksPage /> : <LegacyPage />}
        </Route>
        <Route exact path={`${path}/contacts`}>
          {hasFFUxRedesignV2_5 ? <ContactsPage /> : <LegacyPage />}
        </Route>
        <Route exact path={`${path}/cms/calendar`}>
          {hasFFUxRedesignV2_5 &&
          getFeatureFlag('temp_lggdev-1623_temporary-calendar-feature-flag') ? (
            <CalendarPage />
          ) : (
            <LegacyPage />
          )}
        </Route>
        {import.meta.env.MODE !== 'production' &&
          getFeatureFlag('automated-tests-hello-world') && (
            <Route
              path={`${path}/feature-flags-test-route`}
              render={() => {
                return <div>Hello, World!</div>;
              }}
            />
          )}
        {import.meta.env.MODE === 'development' && (
          <Route path={`${path}/dev-file-attachments`} component={DevFileAttachments} />
        )}
        {import.meta.env.MODE !== 'production' && (
          <ModalRoute path={`${path}/url-modal/:id`}>
            <UrlModal />
          </ModalRoute>
        )}
        <ModalRoute path={`${path}/contact/interaction/:contactInteractionId/`}>
          {hasFeatureConversationAccess ? (
            <AsyncConversationModalRedirect />
          ) : (
            <LegacyModalRedirect
              backgroundUrl={contactsPageUrl}
              resolveUrl={(params, urls) => {
                return urls.getLegacyContactInteractionModalUrl(
                  params['contactInteractionId']!,
                );
              }}
            />
          )}
        </ModalRoute>
        <Route path="*">
          <LegacyPage />
        </Route>
        <ModalRoute
          exact
          path={[
            `${path}/modal/conversation/:conversationId`,
            `${path}/modal/conversation/:conversationId/:contactInteractionId`,
          ]}
          children={<ConversationUrlModal />}
        />
        <ModalRoute
          exact
          path={[
            `${path}/modal/contact/:contactId`,
            `${path}/modal/contact/:contactId/:tab`,
            `${path}/modal/contact/:contactId/conversations/:conversationId`,
            `${path}/modal/contact/:contactId/conversations/:conversationId/:contactInteractionId`,
          ]}
        >
          {showNewContactModal ? (
            <ContactModal />
          ) : (
            <LegacyModalRedirect
              backgroundUrl={contactsPageUrl}
              resolveUrl={(params, urls) => {
                return urls.getLegacyContactDetailUrl(params['contactId']!);
              }}
            />
          )}
        </ModalRoute>
        <ModalRoute exact path={`${path}/modal/task/:taskId`}>
          <LegacyModalRedirect
            backgroundUrl={tasksPageUrl}
            resolveUrl={(params, urls) => {
              return urls.getLegacyTaskEditUrl(params['taskId']!);
            }}
          />
        </ModalRoute>
        <ModalRoute exact path={`${path}/modal/task/edit/contact/:contactId`}>
          <LegacyModalRedirect
            backgroundUrl={contactsPageUrl}
            resolveUrl={(params, urls) => {
              return urls.getLegacyContactAddTaskUrl(params['contactId']!);
            }}
          />
        </ModalRoute>
        <ModalRoute
          exact
          path={[
            `${path}/modal/schedule/edit/:scheduleId`,
            `${path}/modal/schedule/edit/:scheduleId/contact/:contactId`,
          ]}
        >
          <LegacyModalRedirect
            backgroundUrl={appointmentsPageUrl}
            resolveUrl={(params, urls) => {
              return urls.getLegacyScheduleEditUrl(
                params['scheduleId']!,
                params['contactId'],
              );
            }}
          />
        </ModalRoute>
        <ModalRoute exact path={`${path}/modal/schedule/edit/contact/:contactId`}>
          <LegacyModalRedirect
            backgroundUrl={contactsPageUrl}
            resolveUrl={(params, urls) => {
              return urls.getLegacyContactAddScheduleUrl(params['contactId']!);
            }}
          />
        </ModalRoute>
        <ModalRoute exact path={`${path}/modal/call/contact/:contactId`}>
          <CallContactModal />
        </ModalRoute>
      </LggSwitch>
      <EventTriggeredConversationModal />
      <EventTriggeredContactStatusModal />
      <UnblockContactModal />
      <ProfileSettingsModal />
    </>
  );
});

const GET_CONVERSATION_FROM_CONTACT_INTERACTION = gql`
  query GetConversationFromContactInteraction($id: Int!) {
    contactInteraction(id: $id) {
      ... on ContactInteraction {
        id
        contact {
          id
        }
        conversation {
          id
        }
      }
    }
  }
`;

const OverlayBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #727d8e;
`;

export const FullPageLoading = () => (
  <OverlayBackground>
    <Lottie
      animationData={whiteLoaderAnimation}
      loop={true}
      style={{ width: '50px', height: '50px', opacity: '0.5' }}
    />
  </OverlayBackground>
);

const AsyncConversationModalRedirect = memo(() => {
  const { contactInteractionId } = useParams<{ contactInteractionId: string }>();
  const history = useHistory();
  const institutionUrl = useInstitutionUrl();
  const handleGraphQLError = useHandleGraphQLError();
  const { setLoading } = useService(GlobalLoadingService);
  const { getContactModalUrl } = useUrls();
  const showNewContactModal = useShowNewContactModal();

  useQuery<
    {
      contactInteraction: ContactInteractionUnion;
    },
    {
      id: number;
    }
  >(GET_CONVERSATION_FROM_CONTACT_INTERACTION, {
    variables: {
      id: parseInt(contactInteractionId),
    },
    onCompleted: ({ contactInteraction }) => {
      const contactInteractionId = contactInteraction.id;
      const conversationId = contactInteraction.conversation?.id;

      if (showNewContactModal) {
        setLoading(false);
        history.replace(
          getContactModalUrl(contactInteraction.contact.id, {
            contactInteractionId,
            conversationId,
          }),
        );
      } else {
        history.replace(
          `${institutionUrl}modal/conversation/${conversationId}/${contactInteractionId}`,
        );
      }
    },
    onError: (error) => handleGraphQLError(error, { isNavigation: true }),
  });

  useLayoutEffect(() => {
    setLoading(true);
  }, [setLoading]);

  return null;
});

const UrlModal = memo(() => {
  const params = useParams<{ id: string }>();
  const { id: institutionId } = useCurrentInstitution();
  const institutionUrl = useInstitutionUrl();
  const { goBack } = useModalUrl({
    backgroundLocation: `/i/company/${institutionId}/conversations`,
  });
  const modalId = params['id'];

  return (
    <Modal
      visible
      closable
      closeIcon={
        <Icon
          onClick={goBack}
          lggTestId={`url-modal-close-icon-${modalId}`}
          type="close"
        />
      }
    >
      <FlexColumn data-lgg-id={`url-modal-${modalId}`}>
        <FlexRow>
          <Link data-lgg-id="link-to-modal-1" to={`${institutionUrl}url-modal/1`}>
            Go to Modal1
          </Link>
          <Link data-lgg-id="link-to-modal-2" to={`${institutionUrl}url-modal/2`}>
            Go to Modal2
          </Link>
          <Link data-lgg-id="link-to-modal-3" to={`${institutionUrl}url-modal/3`}>
            Go to Modal3
          </Link>
        </FlexRow>
        <div data-lgg-id={`url-modal-content-${modalId}`}>{`Url Modal ${modalId}`}</div>
      </FlexColumn>
    </Modal>
  );
});
